/* =============   Form Element  ============= */

.form-control {
  font-size: 13px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  border-radius: 0px;
  box-shadow: none;
  border: 1px solid #dde6e9;
  transition-duration: .5s;
  display: block;
  width: 100%;
  height: 39px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
}

input {
  &[type="text"], &[type="email"] {
    font-size: 13px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    border-radius: 0px;
    box-shadow: none;
    border: 1px solid #dde6e9;
    transition-duration: .5s;
    display: block;
    width: 100%;
    height: 39px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
  }
}

textarea {
  font-size: 13px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  border-radius: 0px;
  box-shadow: none;
  border: 1px solid #dde6e9;
  transition-duration: .5s;
  display: block;
  width: 100%;
  height: 39px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
}

.form-control:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  box-shadow: none;
  outline: 0 !important;
  border-color: $primary;
}

input {
  &[type="text"]:focus, &[type="email"]:focus {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    box-shadow: none;
    outline: 0 !important;
    border-color: $primary;
  }
}

textarea:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  box-shadow: none;
  outline: 0 !important;
  border-color: $primary;
}

.input-xs {
  height: 24px;
  padding: 2px 10px;
  font-size: 10px;
  line-height: 1.5;
  border-radius: 3px;
  font-weight: 400;
}

.checkbox label, .radio label {
  padding-left: 0px;
}

.checkbox {
  label {
    span {
      display: inline-block;
      vertical-align: middle;
      padding-left: 5px;
      font-size: 13px;
      line-height: 22px;
    }
    font-weight: 300;
    display: block;
    padding-left: 30px;
    position: relative;
    &::before {
      -o-transition: 0.3s ease-in-out;
      -webkit-transition: 0.3s ease-in-out;
     background-color: #dee2e6;
      content: "";
      display: block;
      height: 20px;
      width: 20px;
      left: 0;
      position: absolute;
      transition: 0.3s ease-in-out;
      outline: none !important;
      border-radius:3px;
    }
    &::after {
    position: absolute;
    top: 0px;
    left: 0px;
    display: block;
    width: 20px;
    height: 20px;
    content: "";
    font-size: 10px;
    text-align:center;
    line-height:21px;
    }
  }
  padding-left: 5px;
  input[type="checkbox"] {
    cursor: pointer;
    opacity: 0;
    z-index: 1;
    outline: none !important;
    &:disabled + label {
      opacity: 0.65;
    }
    &:focus + label::before {
      outline-offset: -2px;
      outline: none;
      outline: thin dotted;
    }
    &:checked + label::after {
      content: "\f00c";
      font-family: 'FontAwesome';
    }
    &:disabled + label::before {
      background-color: #e4e7ea;
      cursor: not-allowed;
    }
  }
  &.checkbox-circle label::before {
    border-radius: 50%;
  }
  &.checkbox-inline {
    margin-top: 0;
  }
  &.checkbox-single label {
    height: 17px;
  }
}

.checkbox-primary input[type="checkbox"]:checked + label {
  &::before {
    background-color: $primary;
    border-color: $primary;
  }
  &::after {
    color: $white;
  }
}

.checkbox-danger input[type="checkbox"]:checked + label {
  &::before {
    background-color: #fb9678;
    border-color: #fb9678;
  }
  &::after {
    color: $white;
  }
}

.checkbox-info input[type="checkbox"]:checked + label {
  &::before {
    background-color: #56afd7;
    border-color: #56afd7;
  }
  &::after {
    color: $white;
  }
}

.checkbox-warning input[type="checkbox"]:checked + label {
  &::before {
    background-color: #f8c200;
    border-color: #f8c200;
  }
  &::after {
    color: $white;
  }
}

.checkbox-success input[type="checkbox"]:checked + label {
  &::before {
    background-color: $success;
    border-color: $success;
  }
  &::after {
    color: $white;
  }
}

.checkbox-purple input[type="checkbox"]:checked + label {
  &::before {
    background-color: #796AEE;
    border-color: #796AEE;
  }
  &::after {
    color: $white;
  }
}

.checkbox-red input[type="checkbox"]:checked + label {
  &::before {
    background-color: #fb9678;
    border-color: #fb9678;
  }
  &::after {
    color: $white;
  }
}

.checkbox-inverse input[type="checkbox"]:checked + label {
  &::before {
    background-color: #4c5667;
    border-color: #4c5667;
  }
  &::after {
    color: $white;
  }
}

.radio {
  padding-left: 20px;
  label {
    display: inline-block;
    padding-left: 5px;
    position: relative;
    line-height: 18px;
    &::before {
      -o-transition: border 0.5s ease-in-out;
      -webkit-transition: border 0.5s ease-in-out;
      background-color: $white;
      border-radius: 50%;
      border: 1px solid rgba(120, 130, 140, 0.5);
      content: "";
      display: inline-block;
      height: 17px;
      left: 0;
      margin-left: -20px;
      position: absolute;
      transition: border 0.5s ease-in-out;
      width: 17px;
      outline: none !important;
    }
    &::after {
      -moz-transition: -moz-transform 0.3s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      -ms-transform: scale(0, 0);
      -o-transform: scale(0, 0);
      -o-transition: -o-transform 0.3s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      -webkit-transform: scale(0, 0);
      -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      background-color: #2b2b2b;
      border-radius: 50%;
      content: " ";
      display: inline-block;
      height: 7px;
      left: 5px;
      margin-left: -20px;
      position: absolute;
      top: 5px;
      transform: scale(0, 0);
      transition: transform 0.3s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      width: 7px;
    }
  }
  input[type="radio"] {
    cursor: pointer;
    opacity: 0;
    z-index: 1;
    outline: none !important;
    &:disabled + label {
      opacity: 0.65;
    }
    &:focus + label::before {
      outline-offset: -2px;
      outline: 5px auto -webkit-focus-ring-color;
      outline: thin dotted;
    }
    &:checked + label::after {
      -ms-transform: scale(1, 1);
      -o-transform: scale(1, 1);
      -webkit-transform: scale(1, 1);
      transform: scale(1, 1);
    }
    &:disabled + label::before {
      cursor: not-allowed;
    }
  }
  &.radio-inline {
    margin-top: 0;
  }
  &.radio-single label {
    height: 17px;
  }
}

.radio-primary input[type="radio"] {
  + label::after {
    background-color: $primary;
  }
  &:checked + label {
    &::before {
      border-color: $primary;
    }
    &::after {
      background-color: $primary;
    }
  }
}

.radio-danger input[type="radio"] {
  + label::after {
    background-color: $danger;
  }
  &:checked + label {
    &::before {
      border-color: $danger;
    }
    &::after {
      background-color: $danger;
    }
  }
}

.radio-info input[type="radio"] {
  + label::after {
    background-color: #56afd7;
  }
  &:checked + label {
    &::before {
      border-color: #56afd7;
    }
    &::after {
      background-color: #56afd7;
    }
  }
}

.radio-warning input[type="radio"] {
  + label::after {
    background-color: #f8c200;
  }
  &:checked + label {
    &::before {
      border-color: #f8c200;
    }
    &::after {
      background-color: #f8c200;
    }
  }
}

.radio-success input[type="radio"] {
  + label::after {
    background-color: $success;
  }
  &:checked + label {
    &::before {
      border-color: $success;
    }
    &::after {
      background-color: $success;
    }
  }
}

.radio-purple input[type="radio"] {
  + label::after {
    background-color: #796AEE;
  }
  &:checked + label {
    &::before {
      border-color: #796AEE;
    }
    &::after {
      background-color: #796AEE;
    }
  }
}

.radio-red input[type="radio"] {
  + label::after {
    background-color: #fb9678;
  }
  &:checked + label {
    &::before {
      border-color: #fb9678;
    }
    &::after {
      background-color: #fb9678;
    }
  }
}

.radio-inverse input[type="radio"] {
  + label::after {
    background-color: #4c5667;
  }
  &:checked + label {
    &::before {
      border-color: #4c5667;
    }
    &::after {
      background-color: #4c5667;
    }
  }
}

.fileupload {
  overflow: hidden;
  position: relative;
  input.upload {
    cursor: pointer;
    filter: alpha(opacity = 0);
    font-size: 20px;
    margin: 0;
    opacity: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.group-icon {
  position: relative;
  overflow: hidden;
  .form-control {
    padding-right: 40px;
  }
  .icon-input {
    position: absolute;
    right: 0px;
    top: 10px;
    width: 40px;
    text-align: center;
  }
}

fieldset {
  padding-bottom: 20px;
  border-bottom: 1px dashed #eee;
  margin-bottom: 20px;
}

.form-control-rounded {
  border-radius: 100px;
}

@media (min-width: 768px) {
  .form-horizontal .control-label {
    margin-bottom: 0;
    text-align: right;
  }
}

legend {
  padding-bottom: 5px;
}

.form-control {
  box-shadow: 0 0 0 #000 !important;
}

.has-success {
  .checkbox, .checkbox-inline, &.checkbox-inline label, &.checkbox label, .control-label, .help-block, .radio, .radio-inline, &.radio-inline label, &.radio label {
    color: $success;
  }
}

.has-warning {
  .checkbox, .checkbox-inline, &.checkbox-inline label, &.checkbox label, .control-label, .help-block, .radio, .radio-inline, &.radio-inline label, &.radio label {
    color: #f8c200;
  }
}

.has-danger .help-block {
  color: $danger;
}

.has-error {
  .checkbox, .checkbox-inline, &.checkbox-inline label, &.checkbox label, .control-label, .help-block, .radio, .radio-inline, &.radio-inline label, &.radio label {
    color: $danger;
  }
}

.has-success .form-control {
  border-color: $success;
}

.has-warning .form-control {
  border-color: #f8c200;
}

.has-danger .form-control, .has-error .form-control {
  border-color: $danger;
}

.input-group-addon {
  border-radius: 0;
  font-size: 14px;
}

.input-group {
  padding: 0px;
}

.fileinput-preview {
  margin-bottom: 10px;
  border: 1px solid #ccc;
  padding: 5px;
}