/* =============  Nano Scroll  ============= */

.nano {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  > .nano-content {
    position: absolute;
    overflow: scroll;
    overflow-x: hidden;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    &:focus {
      outline: thin dotted;
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.has-scrollbar > .nano-content::-webkit-scrollbar {
  display: block;
}

.nano {
  > .nano-pane {
   background: transparent;
    position: absolute;
    width: 5px;
    right: 0;
    top: 0;
    bottom: 0;
    visibility: hidden\9;
    /* Target only IE7 and IE8 with this hack */
    opacity: .01;
    -webkit-transition: .2s;
    -moz-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    > .nano-slider {
      background: #444;
      background: rgba(255, 255, 255, 0.1);
      position: relative;
      margin: 0 1px;
      -moz-border-radius: 3px;
      -webkit-border-radius: 3px;
      border-radius: 3px;
    }
  }
  &:hover > .nano-pane {
    visibility: visible\9;
    /* Target only IE7 and IE8 with this hack */
    opacity: 0.99;
  }
}

.nano-pane {
  &.active, &.flashed {
    visibility: visible\9;
    /* Target only IE7 and IE8 with this hack */
    opacity: 0.99;
  }
}