/* =============   Pricing   ============= */

.price-box {
  text-align: center;
  .price-amount {
    padding: 15px;
    border-bottom: 1px solid #ddd;
    font-size: 40px;
    line-height: 40px;
    font-weight: 700;
    sup, sub {
      font-weight: 400;
      font-size: 13px;
    }
    sup {
      top: -1.5em;
    }
  }
  ul {
    margin: 0;
    padding: 0;
    li {
      padding: 7px 0;
    }
  }
}