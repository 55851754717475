.main-nav {
  width: 100%;
  float: left;
  z-index: 999;
}

ul {
  margin: 0px;
  padding: 0px;
}

.ace-responsive-menu {
  list-style: none;
  margin: 0;
  padding: 0 15px;
  float: left;
  width: 100%;
  font-family: 'Rubik', sans-serif;
  li {
    list-style: none;
    ul {
      display: none;
    }
    a {
      color: #ffffff;
      i {
        padding-right: 5px;
        color: #FF5737;
      }
    }
    ul.sub-menu li a i {
      padding-right: 10px;
    }
    &.menu-active > a {
      background: rgba(255, 255, 255, 0.1) !important;
      color: #fff;
    }
    .menu-active {
      position: relative;
    }
    ul.sub-menu {
      li {
        > a > .arrow:before {
          content: "\f105" !important;
        }
        a {
          display: block;
          margin: 0px 0px;
          padding: 12px 20px 12px 15px;
          text-decoration: none;
          font-size: 13px;
          font-weight: normal;
          background: none;
        }
      }
      background: #6174db;
      > li {
        width: 200px;
      }
    }
  }
  > li {
    display: block;
    margin: 0;
    padding: 0;
    border: 0px;
    float: left;
  }
  > li > a {
    display: block;
    position: relative;
    margin: 0;
    border: 0px;
    padding: 15px 15px 15px 15px;
    text-decoration: none;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
  }
  > li > a i {
    font-size: 15px;
    text-shadow: none;
    color: #fff;
  }
  > li > a > .arrow:before {
    margin-left: 15px;
    display: inline;
    font-size: 15px;
    font-family: FontAwesome;
    height: auto;
    content: "\f107";
    font-weight: 300;
    text-shadow: none;
    width: 10px;
    display: inline-block;
  }
  > li > ul.sub-menu {
    display: none;
    list-style: none;
    clear: both;
    margin: 0;
    position: absolute;
    z-index: 99;
  }
  > li > ul.sub-menu {
    > li {
      position: relative;
      ul.sub-menu {
        position: absolute;
        left: 200px;
        top: 0px;
        display: none;
        list-style: none;
        > li ul.sub-menu {
          position: absolute;
          left: 200px;
          top: 0px;
          display: none;
          list-style: none;
        }
      }
    }
    li > a > .arrow:before {
      float: right;
      margin-top: 1px;
      margin-right: 0px;
      display: inline;
      font-size: 16px;
      font-family: FontAwesome;
      height: auto;
      content: "\f104";
      font-weight: 300;
      text-shadow: none;
    }
  }
}

/* Menu Toggle Btn
----------------------------------------*/

.menu-toggle {
  display: none;
  float: left;
  width: 100%;
  background-image: linear-gradient(to right, #6d8ad7, #657fda, #6174db, #6068db, #625ada);
  h3 {
    float: left;
    color: #FFF;
    padding: 20px 10px 0px 10px;
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 0;
  }
  .icon-bar {
    display: block !important;
    width: 18px;
    height: 2px;
    background-color: #F5F5F5 !important;
    -webkit-border-radius: 1px;
    -moz-border-radius: 1px;
    border-radius: 1px;
    -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
    margin: 3px;
    &:hover {
      background-color: #F5F5F5 !important;
    }
  }
  #menu-btn {
    float: right;
    background: none;
    border: none;
    padding: 8px;
    border-radius: 5px;
    cursor: pointer;
    margin: 10px;
  }
}

.hide-menu {
  display: none;
}

/* Accordion Menu Styles
----------------------------------------*/

ul {
  &[data-menu-style="accordion"] {
    width: 250px;
    > li {
      display: block;
      margin: 0;
      padding: 0;
      border: 0px;
      float: none !important;
      &:first-child {
        border-top: 2px solid #FD5025;
      }
    }
    li {
      ul.sub-menu > li {
        width: 100%;
      }
      &.menu-active > a > .arrow:before {
        content: "\f107" !important;
      }
      a:hover {
        background: #272727 !important;
      }
    }
    > li > a > .arrow:before {
      float: right;
      content: "\f105";
    }
    > li {
      > {
        ul.sub-menu {
          position: static;
        }
        a i {
          padding-right: 10px;
          color: #FF5737;
        }
        ul.sub-menu > li ul.sub-menu {
          position: static;
          > li ul.sub-menu {
            position: static;
          }
        }
      }
      border-bottom: 1px solid #242424;
    }
    ul.sub-menu li.menu-active > a > .arrow:before {
      content: "\f107" !important;
    }
  }
  &[data-menu-style="vertical"] {
    width: 200px;
    > li {
      float: none;
      &:first-child {
        border-top: 2px solid #FD5025;
      }
    }
    li {
      ul.sub-menu > li {
        width: 100%;
      }
      a:hover {
        background: #272727 !important;
      }
    }
    > li {
      > a > .arrow:before {
        float: right;
        content: "\f105";
      }
      &.menu-active {
        position: relative;
      }
      > {
        ul.sub-menu {
          position: absolute;
          left: 200px;
          top: 0px;
          width: 200px;
        }
        a i {
          padding-right: 10px;
          color: #FF5737;
        }
        ul.sub-menu > li ul.sub-menu {
          position: absolute;
          width: 200px;
          left: 200px;
          > li ul.sub-menu {
            position: absolute;
            width: 200px;
            left: 200px;
          }
        }
      }
      border-bottom: 1px solid #242424;
    }
  }
}

/* Vertical Menu Styles
----------------------------------------*/

/* Responsive Menu Styles
----------------------------------------*/
/*Note: change the max-width asper your requirment and change the same in aceResponsiveMenu({resizeWidth: "768" }) function*/

@media screen and (max-width: 768px) {
  .demo {
    width: 96%;
    padding: 2%;
  }
  ul {
    &[data-menu-style="vertical"], &[data-menu-style="accordion"], &[data-menu-style="vertical"] li ul.sub-menu {
      width: 100% !important;
    }
  }
  .ace-responsive-menu {
    float: left;
    width: 100%;
    > li {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      float: none;
    }
    li a:hover {
      background: #625ada !important;
    }
    > li > a {
      i {
        padding-right: 10px;
      }
      > .arrow:before {
        float: right;
        content: "\f105";
      }
    }
    background: #625ada !important;
    padding: 0;
  }
  li.menu-active > a > .arrow:before {
    content: "\f107" !important;
  }
  .ace-responsive-menu {
    li ul.sub-menu {
      > li {
        width: 100%;
      }
      li {
        ul.sub-menu li {
          a {
            padding-left: 30px;
          }
          ul.sub-menu li a {
            padding-left: 50px;
          }
        }
        &.menu-active > a > .arrow:before {
          content: "\f107" !important;
        }
      }
    }
    > li > ul.sub-menu {
      position: static;
      > li ul.sub-menu {
        position: static;
        > li ul.sub-menu {
          position: static;
        }
      }
    }
  }
}