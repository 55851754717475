/* =============   Notification  ============= */

.notification-list a, .notification-list div {
  display: block;
  border-top: 1px solid #eee;
  display: block;
  position: relative;
  padding: 6px 12px 6px 75px;
  cursor: pointer;
  @extend .clearfix;
  i {
    display: block;
    float: left;
    width: 50px;
    text-align: center;
    height: 50px;
    line-height: 50px;
    font-size: 25px;
  }
  span {
    display: block;
    line-height: 16px;
    font-size: 13px;
    &.notification-icon {
      position: absolute;
      top: 50%;
      left: 10px;
      width: 50px;
      height: 50px;
      margin: -25px 12px 0 0;
      font-size: 14px;
      line-height: 50px;
      text-align: center;
      i {
        display: block;
        width: 50px;
        height: 50px;
        text-align: center;
        font-size: 28px;
        display: block;
        line-height: 40px;
      }
    }
    &.notification-title {
      color: #333;
      padding-bottom: 1px;
    }
    &.notification-description {
      padding-bottom: 3px;
      color: #999;
      font-size: 12px;
    }
    &.notification-time {
      color: #aeaeae;
      font-style: italic;
      font-size: 11px;
    }
  }
  &:hover {
    background-color: #f2f2f2;
  }
}