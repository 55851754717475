.page-header{

  .show{

  }

  margin: 0px;
  padding: 15px 15px;
  margin-bottom: 30px;
  background: #fff;
  -webkit-box-shadow: 0px 1px 15px 1px rgba(69, 65, 78, 0.08);
  box-shadow: 0px 1px 15px 1px rgba(69, 65, 78, 0.08);
  margin-left:240px;
  -moz-transition: all .5s ease;
  -o-transition: all .5s ease;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
  

  h2 {
    margin: 2px 0;
    font-size: 18px;
  }
  .breadcrumb {
    margin: 0px;
    background: transparent;
    padding: 0;
    text-transform: capitalize;
    > .active {
      color: #909fa7;
    }
    > li > a{

    color: #455a64;
    }
  }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: .5rem;
    padding-left: .5rem;
    color: #868e96;
    font-family: 'simple-line-icons';
    content: "\e606";
    font-size:11px;
  }
}

.nav-collapsed {
  &.page-header{
    margin-left:60px;
  }
}