

.page-header {
    h2 {
    margin: 2px 0;
    font-size: 18px;
    text-transform: capitalize;
}

ol, ul, dl {
    margin-top: 0;
    margin-bottom: 1rem;
    margin-left: -2.5rem;
    padding-top: 10px;
}
}

