/* =============   Button  ============= */

.btn-space {
  margin-right: 5px;
  margin-bottom: 5px;
}

.btn {
  border-radius: 2px;
  -webkit-border-radius: 2px;
  font-size: 14px;
  line-height: 1.538462;
  font-weight: 400;
  padding: 8px 20px;
  &.btn-block {
    width:100%;
    display:block;
  }
  &.box-shadow{
    -webkit-box-shadow: 0px 6px 15px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 6px 15px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 6px 15px 0px rgba(0, 0, 0, 0.1);
    border:2px solid!important;
    border-color:#FFFFFF!important;
  }
  &.btn-sm {
    padding: 6px 10px;
  }
  &.btn-lg {
    padding: 10px 30px;
    font-size: 16px;
  }
  &.btn-xs {
    padding: 4px 8px;
    font-size: 11px;
  }
  &.btn-rounded {
    border-radius: 100px;
    -webkit-border-radius: 100px;
    overflow: hidden;
  }
  &.btn-circle {
    border-radius: 100%;
    width:40px;
    height:40px;
    padding:9px 10px;
  }
  &.btn-icon {
    position: relative;
    overflow: hidden;
    padding-left: 50px;
    i {
      position: absolute;
      height: 100%;
      line-height: 100%;
      width: 40px;
      line-height: 38px;
      text-align: center;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
  &.btn-border {
    &:hover {
      opacity: 1 !important;
    }
    background-color: transparent;
    &.btn-default {
      border: 1px solid #dde6e9;

      &:hover {
        background-color: $primary;
      }
      &:focus {
        background-color: $primary;
      }
    }
    &.btn-primary {
      color: $primary;
      border: 1px solid $primary;
      &:focus {
        background-color: transparent;
      }
      &:hover {
        background-color: $primary;
        color: #fff;
      }
    }
    &.btn-success {
      color: $success;
      border: 1px solid $success;
      &:focus {
        background-color: transparent;
      }
      &:hover {
        background-color: $success;
        color: #fff;
      }
    }
    &.btn-info {
      color: #56afd7;
      border: 1px solid #56afd7;
      &:focus {
        background-color: transparent;
      }
      &:hover {
        background-color: #56afd7;
        color: #fff;
      }
    }
    &.btn-warning {
      color: #f8c200;
      border: 1px solid #f8c200;
      &:focus {
        background-color: transparent;
      }
      &:hover {
        background-color: #f8c200;
        color: #fff;
      }
    }
    &.btn-danger {
      color: $danger;
      border: 1px solid $danger;
      &:focus {
        background-color: transparent;
      }
      &:hover {
        background-color: $danger;
        color: #fff;
      }
    }
    &.btn-indigo {
      color: #796AEE;
      border: 1px solid #796AEE;
      &:focus {
        background-color: transparent;
      }
      &:hover {
        background-color: #796AEE;
        color: #fff;
      }
    }
    &.btn-teal {
      color: #26A69A;
      border: 1px solid #26A69A;
      &:focus {
        background-color: transparent;
      }
      &:hover {
        background-color: #26A69A;
        color: #fff;
      }
    }
  }
}

.btn-facebook {
  color: $white !important;
  background-color: #3b5998 !important;
}

.btn-twitter {
  color: $white !important;
  background-color: #55acee !important;
}

.btn-linkedin {
  color: $white !important;
  background-color: #007bb6 !important;
}

.btn-dribbble {
  color: $white !important;
  background-color: #ea4c89 !important;
}

.btn-googleplus {
  color: $white !important;
  background-color: #dd4b39 !important;
}

.btn-instagram {
  color: $white !important;
  background-color: #3f729b !important;
}

.btn-pinterest {
  color: $white !important;
  background-color: #cb2027 !important;
}

.btn-dropbox {
  color: $white !important;
  background-color: #007ee5 !important;
}

.btn-flickr {
  color: $white !important;
  background-color: #ff0084 !important;
}

.btn-tumblr {
  color: $white !important;
  background-color: #32506d !important;
}

.btn-skype {
  color: $white !important;
  background-color: #00aff0 !important;
}

.btn-youtube {
  color: $white !important;
  background-color: #bb0000 !important;
}

.btn-github {
  color: $white !important;
  background-color: #171515 !important;
}

.btn-default {
  background-color: #fff !important;
  border-color: #dde6e9 !important;
  &:hover {
    background-color: $primary !important;
    border-color: $primary !important;
    color:#fff;
    opacity: 0.9;
  }
  &:focus {
    border-color: $primary !important;
    background:  $primary !important;
    color:#fff;
  }
}

.btn-primary {
  background-color: $primary !important;
  border-color: $primary !important;
  color: #fff !important;
  &:hover {
    color: #fff !important;
    opacity: 0.9;
    background-color: $primary !important;
    border-color: $primary !important;
  }
  &:focus {
    color: #fff;
    background-color: $primary !important;
    border-color: $primary !important;
  }
}

.btn-info {
  background-color: $info !important;
  border-color: $info !important;
  color: #fff !important;
  &:hover {
    color: #fff !important;
    opacity: 0.9;
    background-color: $info !important;
    border-color: $info !important;
  }
  &:focus {
    color: #fff !important;
    background-color: $info !important;
    border-color: $info !important;
  }
}

.btn-success {
  background-color: $success !important;
  border-color: $success !important;
  color: #fff !important;
  &:hover {
    color: #fff !important;
    opacity: 0.9;
    border-color: $success !important;
  }
  &:focus {
    color: #fff !important;
    border-color: $success !important;
  }
}

.btn-warning {
  background-color: $warning !important;
  border-color: $warning !important;
  color: #fff !important;
  &:hover {
    color: #fff !important;
    opacity: 0.9;
    background-color: $warning !important;
    border-color: $warning !important;
  }
  &:focus {
    color: #fff !important;
    background-color: $warning !important;
    border-color: $warning !important;
  }
}

.btn-danger {
  background-color: $danger !important;
  border-color: $danger !important;
  color: #fff !important;
  &:hover {
    color: #fff !important;
    opacity: 0.9;
    background-color: $danger !important;
    border-color: $danger !important;
  }
  &:focus {
    color: #fff !important;
    background-color: $danger !important;
    border-color: $danger !important;
  }
}

.btn-teal {
  background-color: $teal !important;
  border-color: $teal !important;
  color: #fff !important;
  &:hover {
    color: #fff !important;
    opacity: 0.9;
    border-color: $teal !important;
  }
  &:focus {
    color: #fff !important;
    border-color: $teal !important;
  }
}

.btn-indigo {
  background-color: $indigo !important;
  border-color: $indigo !important;
  color: #fff !important;
  &:hover {
    color: #fff !important;
    opacity: 0.9;
  }
  &:focus {
    color: #fff !important;
  }
}

button{
  &.dropdown-toggle{
    &:after{
    top:9px;
    }
  }
}