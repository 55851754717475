/* =============   Helper	============= */

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.no-margin {
  margin: 0px !important;
}

.text-sm {
  font-size: 12px !important;
}

.font-100 {
  font-weight: 100 !important;
}

.font-300 {
  font-weight: 300 !important;
}

.font-400 {
  font-weight: 400 !important;
}

.font-500 {
  font-weight: 500 !important;
}

.font-600 {
  font-weight: 600 !important;
}

.font-700 {
  font-weight: 700 !important;
}

.overflow-hidden {
  overflow: hidden;
}

.mv-0 {
  margin: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-10{
  margin-top: 10px !important;
}
.mt-20 {
  margin-top: 20px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.fs-large {
  font-size: 124px;
}


.circle-border {
  border: 1px solid #dde6e9;
  padding: 5px;
}

.br-t-3 {
  border-top-left-radius: 3px !important;
  border-top-right-radius: 3px !important;
}

.br-b-3 {
  border-bottom-left-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
}

.br {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}

.brs {
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.padding-20 {
  padding: 20px !important;
}

.no-padding {
  padding: 0 !important;
}

.pv-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.pv-l-0 {
  padding-left: 0 !important;
}

.pv-r-0 {
  padding-right: 0 !important;
}

.pv-15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.pl-15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.padding-15 {
  padding: 15px !important;
}

.padding-5 {
  padding: 5px !important;
}

.padding-0 {
	padding: 0px !important;
}

.padding-b-80{
  padding-bottom:80px!important;
}

.margin-b-0 {
	margin-bottom: 0px!important;
}

.margin-b-5 {
	margin-bottom: 5px!important;
}

.margin-b-10 {
	margin-bottom: 10px!important;
}

.margin-b-20 {
	margin-bottom: 20px!important;
}

.margin-b-30 {
	margin-bottom: 30px!important;
}

.margin-b-40 {
	margin-bottom: 40px!important;
}

.margin-b-50 {
	margin-bottom: 50px!important;
}

.margin-r-5 {
	margin-right: 5px!important;
}

.margin-r-10 {
	margin-right: 10px!important;
}

.margin-l-5 {
	margin-left: 5px!important;
}

.margin-l-10 {
	margin-left: 10px!important;
}

.margin-tb-10 {
	margin: 10px 0!important;
}

.margin-tb-20 {
	margin: 20px 0!important;
}

.margin-tb-30 {
	margin: 30px 0!important;
}

.pad-v {
	padding: 10px 0;
}

.fullwidth {
	margin-left: -30px;
	margin-right: -30px;
	padding: 0px 30px;
}

.font-lg {
	font-size: 60px;
	line-height: 60px;
}

.bg-chart{
  background-image: url(../img/world-map.png);
  background-repeat:no-repeat;
  background-color:#f3f5f7!important;
  background-size:cover;
  box-shadow:none!important;
  padding-bottom:15px;
}

.no-background{
  background:none!important;
}

.no-shadow{
  box-shadow:none!important;
}