/* =============   Tabs   ============= */

ul.tabs-nav > li > a {
  font-weight: 400;
  font-size: 13px;
  text-transform: capitalize;
}

.card .tabs {
  margin-left: -15px;
  margin-right: -15px;
  ul.tabs-bottom {
    margin-bottom: -15px;
  }
  .tab-content {
    padding: 15px 15px;
  }
  &.left-tabs {
    margin: 0px;
    .tabs-nav {
      float: left;
      border-bottom: 0px;
      > li {
        margin-top: 5px;
        > a {
          border-bottom: 0px;
          background-color: #f5f5f5;
          text-align: center;
        }
        &.active a {
          background-color: $primary;
          color: #fff;
        }
        a {
          &:hover, &:focus {
            background-color: $primary;
            color: #fff;
          }
        }
      }
    }
    .tab-content {
      overflow: hidden;
      padding: 0px 15px;
    }
  }
  &.right-tabs {
    margin: 0px;
    .tabs-nav {
      float: right;
      border-bottom: 0px;
      > li {
        margin-top: 5px;
        > a {
          border-bottom: 0px;
          background-color: #f5f5f5;
          text-align: center;
        }
        &.active a {
          background-color: $primary;
          color: #fff;
        }
        a {
          &:hover, &:focus {
            background-color: $primary;
            color: #fff;
          }
        }
      }
    }
    .tab-content {
      overflow: hidden;
      padding: 0px 15px;
    }
  }
}

.tabs-nav {
  margin: 0;
  padding: 0px;
  border-bottom: 2px solid #ebebeb;
  li {
    margin-bottom: -2px;
    padding: 0px;
    a {
      display: block;
      padding: 6px 15px;
      color: #4d4d4d;
      border-bottom: 2px solid transparent;
    }
  }
}

.nav-tabs {
  .nav-item.show .nav-link {
    color: $primary;
    border-bottom: 2px solid $primary;
    background: none;
  }
  .nav-link {
    &.active {
      color: $primary;
      border-bottom: 2px solid $primary;
      background: none;
    }
    border: none;
  }
}