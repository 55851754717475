$white: #ffffff;
$success: #7bcb4d;
$gray: #e8eff2;
$primary: #4b71fa;
$blue: #03A9F4;
$danger: #f4516c;
$info: #36a3f7;
$teal: #00c5dc;
$indigo: #967ADC;
$warning: #F6BB42;
$dark: #212121;
$muted: #909fa7;

$card-shadow: 0 1px 15px 1px rgba(62,57,107,.07);
$nav-shadow: 0 1px 15px 1px rgba(62,57,107,.07);

$width: 100%;
$height: 100%;

$font-secondary: 'Montserrat', sans-serif;

